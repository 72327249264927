import { requestPost } from '@/utils/axios.js'
export const loginPwd = (params) => {
  return requestPost('/passport/v1/accounts/authcenter/login-pwd', params)
}
export const contryList = (params) => {
  return requestPost('/passport/v1/public/country/list', params)
}
export const sendEmailSms = (params) => {
  return requestPost('/passport/v1/accounts/send/email-sms/verify-code', params)
}
export const loginOtp = (params) => {
  return requestPost('/passport/v1/accounts/authcenter/login-otp', params)
}
export const resetPwd = (params) => {
  return requestPost('/passport/v1/accounts/authcenter/reset-pwd', params)
}
export const resetProfilePwd = (params) => {
  return requestPost('/passport/v1/user/binding/login-with-draw/pwd', params)
}
export const verifyEmailSms = (params) => {
  return requestPost('/passport/v1/accounts/verify/email-sms/code', params)
}
export const register = (params) => {
  return requestPost('/passport/v1/accounts/authcenter/register', params)
}
export const verifyToken = (params) => {
  return requestPost('/passport/v1/accounts/verify/token', params, { loading: false })
}

export const TradeLike = (params) => {
  return requestPost('/passport/v1/user/symbol/optional/operation', params)
}

export const updateEmailMobile = (params) => {
  return requestPost('/passport/v1/user/binding/email-mobile', params)
}
