import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '@/store/index.js'
import { getToken } from '@/utils/token.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: to => {
      return { path: '/market' }
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/register/index.vue'),
    children: [{
      path: '/register',
      name: 'SignUp',
      component: () => import('@/components/auth/signUp/index.vue')
    },
    {
      path: '/register/signUp/:type',
      name: 'SignUp',
      component: () => import('@/components/auth/signUp/index.vue')
    },
    {
      path: '/register/login',
      name: 'Login',
      component: () => import('@/components/auth/login/index.vue')
    },
    {
      path: '/register/verification/:type',
      name: 'Verification',
      component: () => import('@/components/auth/verification')
    },
    {
      path: '/register/password',
      name: 'Password',
      component: () => import('@/components/auth/password/index.vue')
    },
    {
      path: '/register/resetPassWord',
      name: 'ResetPassWord',
      component: () => import('@/components/auth/resetPassWord/index.vue')
    }
    ]
  },
  {
    path: '/market',
    name: 'Market',
    component: () => import('@/views/market/index.vue')
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('@/views/search/index.vue')
  },
  {
    path: '/trade/spot-kline/:symbol',
    name: 'Trade',
    component: () => import('@/views/trade/index.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('@/views/profile/index.vue'),
    children: [{
      path: '/profile',
      name: 'ProfileHome',
      component: () => import('@/components/profile/home/index.vue')
    },
    {
      path: '/profile/picture',
      name: 'ProfilePicture',
      component: () => import('@/components/profile/picture/index.vue'),
      meta: { title: 'picture' }
    },
    {
      path: '/profile/nickname',
      name: 'ProfileNickName',
      component: () => import('@/components/profile/nickname/index.vue'),
      meta: { title: 'nickname' }
    },
    {
      path: '/profile/authentication',
      name: 'Authentication',
      component: () => import('@/components/profile/authentication/index.vue'),
      meta: { title: 'identityAuthentication' }
    },
    {
      path: '/profile/verifyStep1',
      name: 'verifyStep1',
      component: () => import('@/components/profile/authentication/verifyStep1.vue'),
      meta: { title: 'verifyStep1' }
    },
    {
      path: '/profile/language',
      name: 'ProfileLanguage',
      component: () => import('@/components/profile/language/index.vue'),
      meta: { title: 'language' }
    },
    {
      path: '/profile/about',
      name: 'ProfileAbout',
      component: () => import('@/components/profile/about/index.vue'),
      meta: { title: 'about' }
    },
    {
      path: '/profile/information',
      name: 'ProfileInformation',
      component: () => import('@/components/profile/information/index.vue')
    },
    {
      path: '/profile/security',
      name: 'ProfileSecurity',
      component: () => import('@/components/profile/security/index.vue')
    },
    {
      path: '/profile/security-verification/:type',
      name: 'SecurityVerification',
      component: () => import('@/components/profile/security/verification/index.vue')
    },
    {
      path: '/profile/security-password/:type',
      name: 'SecurityChangePassword',
      component: () => import('@/components/profile/security/changePwd/index.vue')
    }]
  },
  {
    path: '/wallets',
    name: 'Wallets',
    component: () => import('@/views/wallets/index.vue')
  },
  {
    path: '/futures',
    name: 'Futures',
    component: () => import('@/views/futures/index.vue')
  },
  {
    path: '/c2c',
    name: 'C2C',
    component: () => import('@/views/c2c/index.vue'),
    children: [{
      path: '/c2c/buyCoin',
      name: 'BuyCoin',
      component: () => import('@/components/c2c/buyCoin/index.vue')
    },
    {
      path: '/c2c/SelectionPaymentMethod',
      name: 'SelectionPaymentMethod',
      component: () => import('@/components/c2c/paymentMethod/index.vue')
    }]
  },
  {
    path: '/history',
    name: 'History',
    component: () => import('@/views/history/index.vue'),
    children: [{
      path: '/history/futures/:type',
      name: 'HistoryFutures',
      component: () => import('@/components/history/futures/index.vue')
    },
    {
      path: '/history/walltes/:type',
      name: 'HistoryWalltes',
      component: () => import('@/components/history/walltes/index.vue')
    },
    {
      path: '/history/orderDetail/:id',
      name: 'OrderDetail',
      component: () => import('@/components/history/orderDetail/index.vue')
    }]
  },
  {
    path: '/deposit',
    name: 'Deposit',
    component: () => import('@/views/deposit/index.vue')
  },
  {
    path: '/depositInfo',
    name: 'DepositInfo',
    component: () => import('@/views/deposit/depositInfo/index.vue')
  },
  {
    path: '/withdraw',
    name: 'Withdraw',
    component: () => import('@/views/withdraw/index.vue')
  },
  {
    path: '/networkWithdraw',
    name: 'NetworkWithdraw',
    component: () => import('@/views/withdraw/networkWithdraw/index.vue')
  },
  {
    path: '/feature/:symbol',
    name: 'Feature',
    component: () => import('@/views/futures/index.vue')
  },
  {
    path: '/swr',
    name: 'SWR',
    component: () => import('@/components/swr/index.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
const checkLoginStatus = [
  'Wallets',
  'Feature'
]
// 解决Vue-Router升级导致的Uncaught(in promise) navigation guard问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  console.log('push')
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
router.beforeEach(async (to, from, next) => {
  const isAuthenticated = Store.state.token || getToken()
  const isUnLogin = !isAuthenticated && checkLoginStatus.indexOf(to.name) > -1
  const isRegister = to.fullPath.indexOf('/register') === -1 && !isAuthenticated
  if (isUnLogin) {
    next('/register/login')
  } else if (isRegister) {
    next()
    sessionStorage.setItem('Authenticated', true)
  } else {
    next()
  }
})

export default router
