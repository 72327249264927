module.exports = {
  nav: {
    home: '首页',
    market: '行情',
    trade: '交易',
    futures: '合约',
    wallets: '资金',
    login: '登录',
    profile: '我的',
    me: '我的',
    unLogin: '登录信息已过期，需要重新登录',
    unAuth: '用户权限已过期，请重新登录',
    reLogin: '请重新登录',
    error: '网络异常',
    search: '搜索',
    cancel: '取消',
    userNoExist: '用户不存在',
    prompted: '系统提示',
    successText: '操作成功'
  },
  checkNetwork: {
    tipMessage: '请求超时，请检查你的网络状态',
    confirmButtonText: '确定'
  },
  anynode: 'anynode',
  welcome: '欢迎来到 Anynode',
  next: '下一步',
  cancel: '取消',
  confirm: '确认',
  search: '搜索',
  select: '选择',
  region: '区域',
  or: '或',
  refresh: '刷新',
  country: {
    title: '选择国家区号'
  },
  auth: {
    login: '登录',
    OPTlogin: '快速登录',
    signup: '注册',
    email: '邮箱',
    phone: '手机号',
    password: '密码',
    enterPhone: '请输入手机号',
    enterPassword: '请输入登录密码',
    forgot: '忘记密码 ?',
    code: '验证码',
    welcomeSignUp: '欢迎注册Anynode',
    welcomeLogIn: '欢迎登录Anynode',
    resetPasswordTip: '修改密码后24小时内禁止提现和OTC交易',
    submit: '提交',
    referralCode: '邀请码',
    loginError: '账号或密码错误,你还可以尝试4次',
    emialErrorTip: '请输入有效的邮箱',
    phoneErrorTip: '请输入有效的手机号',
    OPTLogin: '注册/登录',
    passwordLogin: '密码登录',
    newPassword: '新密码',
    confirmPassword: '确认密码',
    confirmPwdErrorTip: '两次密码不一致',
    newPwdMinLengthTip: '至少输入8个字符',
    newPwdMaxLengthTip: '至多输入16个字符',
    newPwdDigitTip: '密码复杂度过低',
    newPwdLetterTip: '密码复杂度过低',
    setPasswordTitle: '设置密码',
    sendVerificationCode: '发送验证码',
    verificationTitle: '安全验证',
    verificationLabel: '请输入你收到的验证码',
    recent: '发送',
    resetPassWord: '重新设置密码'
  },
  market: {
    favorites: '自选',
    all: '全部',
    name: '名称',
    vol: '成交量',
    currency: '成交量',
    trading: '交易对',
    price: '最新价格',
    change: '24h涨跌',
    market: '行情',
    action: '操作',
    hot: '热币榜',
    newest: '新币榜',
    topVolume: '成交额榜',
    topGainers: '涨幅榜',
    indexPrice: '指数价格',
    markPrice: '标记价格',
    lastPrice: '最新价格',
    highPrice: '最高价',
    lowPrice: '最低价',
    currencyPrice: '成交额',
    today: '今天',
    days7: '7 天',
    days30: '30 天',
    days90: '90 天',
    days180: '180 天',
    year1: '1 年',
    perprtual: '永续',
    bid: '买',
    ask: '卖',
    noDataText: 'Sem dados'
  },
  trade: {
    orderBook: '委托订单',
    marketTrades: '最新成交',
    data: '交易数据',
    projectDetails: '信息',
    chart: '图表',
    Trades: '最新成交',
    buy: '买入',
    sell: '卖出',
    fundingRate: '资金费率',
    countdown: '倒计时',
    fundingText: '做多仓位和做空仓位在下个资金费率时间点须交换的资金费率。费率为正，做多仓位支付做空仓位。费率为负，做空仓位支付做多仓位。',
    indexPrice: '指数价格',
    fairPrice: '合理价格',
    turnover: '额',
    volume: '量',
    low: '低',
    high: '高',
    price: '价格',
    more: 'More',
    depth: 'Depth',
    m1: '1分',
    m3: '3分',
    m5: '5分',
    m30: '30分',
    m15: '15分',
    h1: '1小时',
    h2: '2小时',
    h4: '4小时',
    h6: '6小时',
    h8: '8小时',
    h12: '12小时',
    d1: '1日',
    d3: '3日',
    w1: '1周',
    M1: '1月',
    M: '万',
    time: '时间',
    quantity: '数量',
    favourite: '添加自选成功',
    unFavourite: '移除自选成功',
    noDataText: '数据为空'
  },
  wallets: {
    overview: '总览',
    deposit: '充值',
    spot: '现货',
    futures: '合约',
    funding: '资金账户',
    totalValue: '总资产',
    withdraw: '提现',
    totalBalance: '总资产',
    position: '持有仓位',
    totalAssets: '全部资产',
    transactionHistory: '交易历史',
    sell: '卖',
    cross: '全仓',
    isolated: '逐仓',
    buy: '买',
    pnl: '未实现盈亏',
    roi: '投资回报率',
    size: '持仓数量',
    margin: '保证金',
    marginLevel: '保证金率',
    emptyPrice: '开仓价格',
    marketPrice: '标记价格',
    liqPrice: '强平价格',
    walletBalance: '钱包余额',
    unrealizedPNL: '未实现盈亏',
    marginBalance: '保证金余额',
    availableForWithdrawal: '可用转划余额'
  },
  history: {
    amount: '金额',
    fee: '手续费',
    time: '时间',
    received: '接收',
    withdraw: '提现',
    futuresHistory: '合约历史',
    positionHistorry: '历史委托',
    ordersHistory: '仓位历史',
    orderDetail: '订单详情',
    closeLong: '平多',
    filled: '全部成交',
    perpetual: '永续',
    reduceOnly: ' 只减仓',
    price: '价格',
    symbol: '合约',
    orderNo: '订单号',
    type: '类型',
    filledAmount: '成交数量/数量',
    AvgPrice: '均价/价格',
    realizedPNL: '实现盈亏',
    timeCreated: '创建时间',
    timeUpdated: '更新时间',
    tradeDetails: '成交明细',
    date: '日期',
    quantity: '数量',
    role: '角色'
  },
  coinList: {
    crypto: '数字货币',
    trending: '热门',
    coinList: '币种列表',
    searchHistory: '历史搜索',
    noFound: '未找到记录',
    placeholder: '请输入搜索关键词',
    cash: '法币'
  },
  deposit: {
    deposit: '充值',
    depositTip1: '仅支持对该地址充值',
    depositTip11: ' ',
    depositTip2: '该地址不支持充值NFT,请至NFT页面充值',
    chooseNetwork: '请选择主网即可获取充值地址',
    address: '地址',
    selectedWallet: '选择上账账户',
    spotWallet: '现货钱包',
    minimum: '最小充值数',
    expectedArrival: '区块确认数',
    expectedUnlock: '提现解锁确认数',
    networkConfirmations: '次网络确认',
    contractInformation: '合约信息',
    saveAsImage: '保存图片',
    shareAddress: '分享地址'
  },
  withdraw: {
    withdraw: '提现',
    send: '发送',
    sendTip1: '转账',
    sendTip2: '到数字币地址',
    address: '地址',
    addressIptTip: '长按粘贴',
    amount: '提现金额',
    amountIptTip: '0.00000',
    methodTitle1: '通过区块链转账',
    methodTip1: '通过区块链网络发送到已知的数字货币地址',
    methodTitle2: '通过邮箱/手机/ID转账',
    methodTip2: '发送给xxxx用户。简单，即时，0费用',
    token: '币种',
    balance: '余额',
    fee: '手续费',
    withdrawalLimit: '24小时提币限额',
    wraningTip: '不要直接提款到众筹或ICO。我们不会将该销售的代币记入您的帐户。',
    submit: '提交',
    invoice: '票据',
    available: '可用',
    invoiceDialogContent: '通过BTC闪电网络提现，需要现在平台获取充值票据',
    iKonw: '我已知悉',
    selectAddress: '选择地址',
    insufficientBalance: '余额不足'
  },
  chooseNetwork: {
    network: '主网',
    chooseNetwork: '选择网络',
    slelectNetwork: '',
    slelectNetworkTip: '请先选择主网',
    tip: '当您充值该币种时，请仅限于通过下方我们支持的网络充值，如果您通过其他网络充值会造成资金丢失'
  },
  pullRefresh: {
    pullingText: ' ',
    loosingText: ' ',
    loadingText: 'loading',
    successText: ' ',
    finishedText: '没有更多了'
  },
  features: {
    allHolder: '全仓',
    halfHolder: '逐仓',
    open: '开仓',
    close: '平仓',
    single: '单',
    union: '联',
    surplus: '止盈',
    loss: '止损',
    maxOpen: '可开',
    cost: '保证金',
    openLong: '开多',
    openShort: '开空',
    advanced: '高级',
    buy: '看涨',
    sell: '看跌',
    amount: '数量',
    limit: '限单价',
    avbl: '可用',
    perpetual: '永续',
    takeProfit: '止盈',
    stopLoss: '止损'
  },
  profile: {
    language: '语言',
    helpCenter: '帮助中心',
    customerService: '客服中心',
    security: '安全',
    aboutBewincoin: '关于我们',
    logOut: '退出',
    logOutText: '你确定要退出吗？',
    copy: '复制成功',
    phone: '手机号',
    email: '邮箱',
    loginPwd: '登录密码',
    withdrawPwd: '取款密码',
    faceID: '面部ID',
    patternPwd: '图形密码',
    inUse: '使用中',
    changeNow: '替换',
    nickApproveText: '我们需要几分钟的时间来审查和批准一个新的全球姓名或昵称。',
    nickChangeText: '您的昵称对所有用户都可见。你可以更改3次在今年年底之前。配额每年重置一次。',
    servicesAgreement: '服务协议',
    checkUpdate: '检查更新',
    clearCache: '清除缓存',
    nav: {
      profile: '资料',
      picture: '照片',
      nickname: '昵称',
      language: '语言',
      about: '关于 Bewincoin',
      information: '个人信息'
    },
    verification: '验证',
    add: '添加',
    code: '码',
    emailCodeTip: '邮箱验证码',
    phoneCodeTip: '手机验证码',
    emailCodeLabel: '邮箱验证码',
    phoneCodeLabel: '手机验证码',
    phoneNoneTip: '电话号码验证为您的提款和帐户增加了另一层保障。',
    emailNoneTip: '电子邮件验证为您的提款和帐户增加了另一层保障。',
    added: '添加',
    chooseVerification: '请选择验证',
    change: '重置',
    disabled: '未绑定',
    linked: '已绑定',
    enter: '请输入',
    verificationCode: '验证码',
    getCode: '获取'
  }
}
