<template>
  <div v-if="show" />
</template>

<script>
import { Dialog } from 'vant'
import { verifyToken } from '@/api/auth.js'
import { getToken } from '@/utils/token.js'

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  // components: {
  //   VanDialog: Dialog
  // },
  created () {
    if (getToken()) {
      this.onCheckToken()
    } else {
      Dialog.confirm({
        title: this.$t('nav.prompted'),
        message: this.$t('auth.login'),
        confirmButtonText: this.$t('nav.login'),
        cancelButtonText: this.$t('nav.cancel')
      })
        .then(() => {
          // on confirm
          sessionStorage.setItem('Authenticated', false)
          this.$router.push('/register/login')
        })
        .catch(() => {
          // on cancel
          sessionStorage.setItem('Authenticated', false)
        })
    }
  },
  methods: {
    onLogin () {
      this.$router.push('/register/login')
    },
    onCheckToken () {
      console.log(1, 'notice')
      verifyToken().then((res) => {
        if (res?.data) {
          // 如果token没问题 设置dialog沉睡
          sessionStorage.setItem('Authenticated', false)
          return
        }
        Dialog.confirm({
          title: this.$t('nav.prompted'),
          message: this.$t('auth.reLogin'),
          confirmButtonText: this.$t('nav.login'),
          cancelButtonText: this.$t('nav.cancel')
        })
          .then(() => {
            // on confirm
            sessionStorage.setItem('Authenticated', false)
            this.$router.push('/register/login')
          })
          .catch(() => {
            sessionStorage.setItem('Authenticated', false)
            // on cancel
          })
      })
    }
  }
}
</script>
