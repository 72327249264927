<template>
  <div id="app" class="theme-light up-green">
    <Header/>
    <van-tabbar :active-color="activeColor" :inactive-color="defaultColor" v-show="tabShowStatus" v-model="active" @change="navChange">
      <van-tabbar-item v-for="(item,index) in navList" :to="onPath(item)" :key="index">
        <span class="nav-tab-text">{{ $t( item.txtValue ) }}</span>
          <img
            class="icon-img"
            slot="icon"
            slot-scope="props"
            :src="props.active ? item.icon.active : item.icon.normal"
          >
      </van-tabbar-item>
    </van-tabbar>
    <router-view :class="{'view': tabShowStatus, 'allView': !tabShowStatus }"/>
    <LoginNotice :show="Authenticated" v-if="Authenticated" />
  </div>
</template>
<script>
import Header from '@/components/header'
import LoginNotice from '@/components/auth/loginNotice'
import { mapGetters } from 'vuex'
export default {
  components: {
    Header,
    LoginNotice
  },
  data () {
    return {
      active: 0,
      activeColor: 'var(--primary-color)',
      defaultColor: 'var(--main-text-2)',
      navList: [
        {
          path: '/market',
          txtValue: 'nav.market',
          icon: {
            normal: require('@/assets/images/nav/markets.svg'),
            active: require('@/assets/images/nav/markets-c.svg')
          }
        },
        {
          path: '/feature',
          txtValue: 'nav.futures',
          icon: {
            normal: require('@/assets/images/nav/trade.svg'),
            active: require('@/assets/images/nav/trade-c.svg')
          }
        },
        {
          path: '/wallets',
          txtValue: 'nav.wallets',
          icon: {
            normal: require('@/assets/images/nav/wallets.svg'),
            active: require('@/assets/images/nav/wallets-c.svg')
          }
        },
        {
          path: '/profile',
          txtValue: 'nav.profile',
          icon: {
            normal: require('@/assets/images/nav/profile.svg'),
            active: require('@/assets/images/nav/profile-c.svg')
          }
        }
      ],
      tabWhiteList: ['Market', 'Feature', 'Wallets', 'ProfileHome'],
      tabShowStatus: true,
      Authenticated: sessionStorage.getItem('Authenticated') === 'true'
    }
  },
  computed: {
    ...mapGetters(['futureCoin'])
  },
  watch: {
    $route (to, from) {
      this.active = this.tabWhiteList.indexOf(this.$route.name)
      this.tabShowStatus = this.tabWhiteList.indexOf(this.$route.name) > -1
    }
  },
  mounted () {
    this.active = this.tabWhiteList.indexOf(this.$route.path)
    this.tabShowStatus = this.tabWhiteList.indexOf(this.$route.path) > -1
    this.$on('storage', this.handleSessionStorageChange)
  },
  destroyed () {
    this.$off('storage', this.handleSessionStorageChange)
  },
  methods: {
    handleSessionStorageChange (event) {
      if (event.key === 'Authenticated') {
        this.Authenticated = event.newValue === 'true'
      }
    },
    navChange () {
      console.log(this.active)
    },
    getSafeAreaBottom () {
      var deviceHeight = Math.max(window.innerHeight, document.documentElement.clientHeight)
      var viewportHeight = Math.max(window.screen.availHeight, window.screen.height)
      return viewportHeight - deviceHeight
    },
    onPath (value) {
      let path = value.path
      if (path === '/feature') {
        path = `${value.path}/${this.futureCoin}`
      }
      return path
    }
  }
}
</script>
<style lang="less">
@import "~@/assets/styles/normalize.less";
@import "~@/assets/styles/common.less";
@import "~@/assets/styles/variable.less";

body {
  box-sizing: border-box;
}
#app {
  height: 100%;
  color: var(--main-text-1);
  background: @--main-bg-1;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容旧版WebKit内核的iOS Safari */
  padding-bottom: env(safe-area-inset-bottom); /* 支持最新版本的iOS Safari */
  .icon-img {
    width: 64px;
    height: 64px;
  }
  .nav-tab-text {
    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
  }
  .view {
    height: calc(100vh - 116px);
    padding-bottom: 116px;
  }
  .allView {
    height: 100vh;
  }
  .van-tabbar {
    padding: 12px 0;
    height: 116px;
    box-sizing: border-box;
    .van-tabbar-item__icon {
      margin-bottom: 0;
    }
  }
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
