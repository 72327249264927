module.exports = {
  nav: {
    home: 'Home',
    market: 'Markets',
    trade: 'Trade',
    futures: 'Futures',
    wallets: 'Wallets',
    login: 'Login',
    profile: 'Profile',
    me: 'Me',
    unLogin: 'Login information has expired, you need to log in again',
    unAuth: 'permissions have expired, please log in again',
    reLogin: 'please log in again',
    error: 'Internal Server Error',
    search: 'Search',
    cancel: 'Cancel',
    userNoExist: 'user does not exist',
    prompted: 'Prompted',
    successText: 'Success'
  },
  checkNetwork: {
    tipMessage: 'Request timed out, please check your network status',
    confirmButtonText: 'OK'
  },
  anynode: 'anynode',
  welcome: 'Welcom to Anynode Global',
  next: 'Next',
  cancel: 'Cancel',
  confirm: 'Confirm',
  search: 'Search',
  region: 'Region',
  select: 'Select',
  or: 'or',
  refresh: 'Refresh',
  country: {
    title: 'Select area code'
  },
  auth: {
    login: 'Log In',
    OPTlogin: 'OPT login',
    signup: 'Sign Up',
    homtTip: 'A message prompts the user to complete the login and registration operation.',
    email: 'Email',
    phone: 'Phone',
    password: 'Password',
    enterPhone: 'Phone Number',
    enterPassword: 'Login Password',
    forgot: 'Forgot password ?',
    code: 'Code',
    submit: 'Submit',
    welcomeSignUp: 'Sign Up with Anynode',
    welcomeLogIn: 'Welcome Back !',
    resetPasswordTip: 'Withdrawals and OTC trading not permitted for 24 hours after password reset.',
    referralCode: 'Referral Code',
    loginError: 'Incorrect account or password. You have 4 attempts left.',
    emialErrorTip: 'Please enter a vaild email',
    phoneErrorTip: 'Please enter a vaild phone',
    OPTLogin: 'Sign up/Log in',
    passwordLogin: 'Password login',
    newPassword: 'New Password',
    confirmPassword: 'Confirm Password',
    confirmPwdErrorTip: 'Entered passwords differ',
    newPwdMinLengthTip: 'At least 8 characters contains',
    newPwdMaxLengthTip: 'At most 16 characters contains',
    newPwdDigitTip: 'Password complexity too low',
    newPwdLetterTip: 'Password complexity too low',
    setPasswordTitle: 'Set a password',
    sendVerificationCode: 'Send verification code',
    verificationTitle: 'Security Verification',
    verificationLabel: 'Phone/Email verification code',
    recent: 'Recent',
    resetPassWord: 'Reset Your Password'
  },
  market: {
    favorites: 'Favorites',
    all: 'All',
    name: 'Name',
    vol: 'Vol',
    currency: 'Vol',
    trading: 'Trading Pair',
    price: 'Last Price',
    change: '24h Change',
    market: 'Market',
    action: 'Action',
    hot: 'Hot',
    newest: 'New',
    topVolume: 'Top Volume',
    topGainers: 'Top Gainers',
    indexPrice: 'Index Price',
    markPrice: 'Mark Price',
    lastPrice: 'Last Price',
    highPrice: 'High',
    lowPrice: 'Low',
    currencyPrice: 'Vol',
    today: 'Today',
    days7: '7 Days',
    days30: '30 Days',
    days90: '90 Days',
    days180: '180 Days',
    year1: '1 Year',
    bid: 'Bid',
    ask: 'Ask',
    perprtual: 'Perprtual',
    noDataText: 'No Data'
  },
  trade: {
    orderBook: 'Order Book',
    marketTrades: 'Trades',
    data: 'Trading Data',
    projectDetails: 'Info',
    chart: 'Chart',
    trades: 'Trades',
    buy: 'Buy',
    sell: 'Sell',
    fundingRate: 'Funding Rate',
    countdown: 'Countdown',
    fundingText: 'The payment rate exchanged between the long and short positions for the next funding. If the funding rate is positive, longs pay shorts. If negative, shorts pay longs.',
    indexPrice: 'Index Price',
    fairPrice: 'FairPrice',
    turnover: 'Turnover',
    volume: 'Volume',
    low: 'Low',
    high: 'High',
    price: 'Price',
    more: 'More',
    depth: 'Depth',
    line: 'Line',
    m1: '1m',
    m3: '3m',
    m5: '5m',
    m30: '30m',
    m15: '15m',
    h1: '1h',
    h2: '2h',
    h4: '4h',
    h6: '6h',
    h8: '8h',
    h12: '12h',
    d1: '1d',
    d3: '3d',
    w1: '1w',
    M1: '1M',
    M: 'M',
    time: 'Time',
    quantity: 'Quantity',
    favourite: 'added to favourite',
    unFavourite: 'Removed from favourite'
  },
  wallets: {
    amount: 'Amount',
    overview: 'Overview',
    buy: 'Buy',
    deposit: 'Deposit',
    spot: 'Spot',
    futures: 'Futures',
    funding: 'Funding',
    totalValue: 'Total Value',
    withdraw: 'Withdraw',
    totalBalance: 'Total Balance',
    position: 'Positons',
    totalAssets: 'Total Assets',
    transactionHistory: 'Transaction History',
    sell: 'Sell',
    cross: 'Cross',
    isolated: 'Isolated',
    pnl: 'PNL',
    roi: 'ROI',
    roe: 'ROE',
    size: 'Size',
    margin: 'Margin',
    marketClose: 'Market Close',
    marginLevel: 'Margin Level',
    emptyPrice: 'Empty Price',
    marketPrice: 'Market Price',
    liqPrice: 'Liq.Price',
    walletBalance: 'Wallet Balance',
    unrealizedPNL: 'Unrealized PNL',
    marginBalance: 'Margin Balance',
    availableForWithdrawal: 'Available For Withdrawal'
  },
  history: {
    amount: 'Amount',
    fee: 'Fee',
    time: 'Time',
    received: 'Received',
    withdraw: 'Withdraw',
    futuresHistory: 'Futures History',
    positionHistorry: 'Position Historry',
    ordersHistory: 'Orders History',
    orderDetail: 'Order Detail',
    closeLong: 'Close Long',
    closeShort: 'Close Short',
    openShort: 'Open Short',
    openLong: 'Open Long',
    filled: 'Filled',
    perpetual: 'Perpetual',
    reduceOnly: ' Reduce Only',
    price: 'Price',
    symbol: 'Symbol',
    orderNo: 'Order No.',
    type: 'Type',
    filledAmount: 'Filled/Amount',
    AvgPrice: 'Avg./Price',
    realizedPNL: 'Realized PNL',
    timeCreated: 'Time Created',
    timeUpdated: 'Time Updated',
    tradeDetails: 'Trade Details',
    date: 'Date',
    quantity: 'Quantity',
    role: 'Role'
  },
  coinList: {
    crypto: 'Crypto',
    trending: 'Trending',
    coinList: 'Coin List',
    noFound: 'No records found',
    searchHistory: 'Search History',
    placeholder: 'Search Coin',
    cash: 'Cash'
  },
  deposit: {
    deposit: 'Deposit',
    depositTip1: 'Send only',
    depositTip11: 'to this deposit address.',
    depositTip2: 'This address does not support deposit of non-fungible token, plase go to NFT page to deposit NFT. ',
    chooseNetwork: 'Choose network to get deposit address',
    address: 'Address',
    selectedWallet: 'Selected Wallet',
    spotWallet: 'Spot Wallet',
    minimum: 'Minimum deposit',
    expectedArrival: 'Expected arrival',
    expectedUnlock: 'Expected unlock',
    networkConfirmations: 'network confirmations',
    contractInformation: 'Contract information',
    saveAsImage: 'Save as Image',
    shareAddress: 'Share Address'
  },
  withdraw: {
    withdraw: 'Withdraw',
    send: 'Send',
    sendTip1: 'Send',
    sendTip2: 'to crypto address',
    address: 'Address',
    addressIptTip: 'Long press to paste',
    amount: 'Amount',
    amountIptTip: 'Minimum 0',
    methodTitle1: 'Send via Crypto Network',
    methodTip1: 'Send to a known crypto address via crypto network.',
    methodTitle2: 'Send via Email/phone/ID',
    methodTip2: 'Send to xxxx use. Easy, Instant, 0 fee',
    token: 'Token',
    balance: 'Balance',
    fee: 'Fee',
    available: 'Available',
    withdrawalLimit: '24h withdrawal limit',
    wraningTip: 'Do not withdraw directly to a crowdfund or ICO. We will not credit your account with tokens from that sale.',
    submit: 'Submit',
    invoice: 'Invoice',
    invoiceDialogContent: 'Please entering address or invoice. Withdrawal via BTC Lightning network needs invoice generated by the deposit platform.',
    iKonw: 'I Konw',
    selectAddress: 'Select Address',
    insufficientBalance: 'Insufficient Balance',
    unitPrice: 'Unit price'
  },
  chooseNetwork: {
    network: 'Network',
    chooseNetwork: 'Choose Network',
    slelectNetwork: '',
    slelectNetworkTip: 'Please choose network first',
    tip: 'Please note that only supported networks on Binance platform are shown, if you deposit via another network your assets may be lost.'
  },
  pullRefresh: {
    pullingText: ' ',
    loosingText: ' ',
    loadingText: 'loading',
    successText: ' ',
    finishedText: 'end'
  },
  features: {
    allHolder: 'Cross',
    halfHolder: 'Isolated',
    open: 'Open',
    close: 'Close',
    single: 'S',
    union: 'M',
    surplus: 'TP',
    loss: 'SL',
    maxOpen: 'Max Open',
    cost: 'Cost',
    openLong: 'Open Long',
    openShort: 'Open Short',
    advanced: 'Advanced',
    buy: 'Buy',
    sell: 'Sell',
    amount: 'Amount',
    limit: 'Limit',
    avbl: 'Avbl',
    perpetual: 'Perpetual',
    takeProfit: 'Take Profit',
    stopLoss: 'Stop Loss',
    min: 'Min',
    slippage: 'Slippage Tolerance',
    adjustLever: 'Adjust Leverage',
    'usd@mfeatures': 'USD@-M Futures',
    insuffcientMargin: 'Insuffcient margin.',
    emptyNumber: 'please enter a valid number',
    purchaseError: 'Abnormal purchase quantity',
    closeText: 'The closing quantity cannot be greater than the holding quantity',
    orderMinText: 'Order notional cannot be less than 5.1 USDT, This does not apply to reduce only orders.',
    stepNotice: 'Your current wallet and assets will be removed from this app permanently. This action cannot be undone.Your current wallet and assets will be removed from this app permanently. This action cannot be undone.'
  },
  profile: {
    language: 'Language',
    help: 'Help',
    helpCenter: 'Help Center',
    customerService: 'Customer Service',
    security: 'Security',
    aboutBewincoin: 'About Bewincoin',
    logOut: 'Log Out',
    logOutText: 'Are you sure you want to log out?',
    copy: 'Copied',
    phone: 'Phone Number',
    email: 'Email',
    loginPwd: 'Login Password',
    withdrawPwd: 'Withdraw Password',
    faceID: 'Face ID',
    patternPwd: 'Pattern Password',
    inUse: 'In Use',
    changeNow: 'Change Now',
    nickApproveText: 'It’ll take a few minutes for us to review and approve a new global name or nickname.',
    nickChangeText: 'Your nickname is visible to all users. You can change it 3 times before the end of this year. The quota resets yearly.',
    servicesAgreement: 'Services Agreement',
    checkUpdate: 'Check for updates',
    clearCache: 'Clear cache',
    verifications: {
      verifications: 'Verifications',
      verified: 'Verified',
      verify: 'Verify',
      approved: 'Approved',
      Unverified: 'Unverified',
      level1: 'Level 1 Basic Permissions',
      level2: 'Level 2 Basic Verification',
      level3: 'Level 3 Advanced Verification',
      withdrawalLimit: 'Withdrawal Limit',
      amountLimit24h: '24h Amount limit: ',
      nationality: 'Nationality',
      countryOfResidence: 'Country of Residence',
      firstName: 'First Name',
      lastName: 'Last Name',
      IDNumber: 'ID Number'
    },
    nav: {
      profile: 'Profile',
      picture: 'Profile Picture',
      nickname: 'Nickname',
      language: 'Language',
      about: 'About Bewincoin',
      information: 'Information',
      identityAuthentication: 'Identity Authentication',
      verifyStep1: 'Level 1 Basic Permissions'
    },
    verification: 'Verification',
    add: 'Add',
    code: 'code',
    emailCodeTip: 'Enter the 6-digit verification code sent to ',
    phoneCodeTip: 'Enter the 6-digit verification code sent to ',
    emailCodeLabel: 'Email verification code',
    phoneCodeLabel: 'Phone verification code',
    phoneNoneTip: 'Phone Number verification adds another layer of sdcurity to your withdraws and account.',
    emailNoneTip: 'Email verification adds another layer of sdcurity to your withdraws and account.',
    added: 'Added',
    chooseVerification: 'Choose Verification',
    change: 'Change',
    disabled: 'Disabled',
    linked: 'Linked',
    enter: 'Enter',
    verificationCode: 'Verification Code',
    getCode: 'Get Code'
  }
}
