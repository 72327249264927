import Vue from 'vue'
import Vuex from 'vuex'
import { setToken, removeToken, getToken } from '@/utils/token.js'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    locale: 'en_US',
    token: '' || getToken(),
    auth: {
      phone: '',
      email: '',
      mobileCode: '',
      KYCStatus: null
    },
    totalAssets: 0,
    verify: {
      sessionId: '',
      verificationTarget: '',
      accountStatus: null,
      expiresTime: '',
      countdown: 0
    },
    userInfo: {},
    favoritesMarket: [],
    langs: [],
    futureCoin: 'BTCUSDT'
  },
  getters: {
    locale: (state) => state.locale,
    token: (state) => state.token,
    favoritesMarket: (state) => state.favoritesMarket,
    langs: (state) => state.langs,
    totalAmount: (state) => state.totalAssets,
    futureCoin: (state) => state.futureCoin
  },
  mutations: {
    updateLocale (state, langValue) {
      state.locale = langValue
      sessionStorage.setItem('localc', langValue)
    },
    updateTotalAssets (state, totalAssets) {
      state.totalAssets = totalAssets
    },
    updateLangs (state, list) {
      state.langs = list
      sessionStorage.setItem('langs', list)
    },
    updateToken (state, token) {
      state.token = token
    },
    updatePhone (state, phone) {
      state.auth.phone = phone
    },
    updateEmail (state, email) {
      state.auth.email = email
    },
    updateMobileCode (state, mobileCode) {
      state.auth.mobileCode = mobileCode
    },
    updateVerificationTarget (state, verificationTarget) {
      state.verify.verificationTarget = verificationTarget
    },
    updateVerificationSessionId (state, sessionId) {
      state.verify.sessionId = sessionId
    },
    updateVerificationAccountStatus (state, accountStatus) {
      state.verify.accountStatus = accountStatus
    },
    updateVerificationExpiresTime (state, expiresTime) {
      state.verify.expiresTime = expiresTime
    },
    updateVerificationCountdown (state, countdown) {
      state.verify.countdown = countdown
    },
    updateFavoritesMarket (state, list) {
      state.favoritesMarket = list
    },
    updateUserInfo (state, userInfo) {
      state.userInfo = userInfo
    },
    updateFutureCoin (state, coin) {
      state.futureCoin = coin
    }
  },
  actions: {
    updateToken ({ commit }, token) {
      commit('updateToken', token)
      setToken(token)
      if (!token) removeToken()
    },
    updateTotalAssets ({ commit }, totalAssets) {
      commit('updateTotalAssets', totalAssets)
    },
    updatePhone ({ commit }, phone) {
      commit('updatePhone', phone)
    },
    updateEmail ({ commit }, email) {
      commit('updateEmail', email)
    },
    updateMobileCode ({ commit }, mobileCode) {
      commit('updateMobileCode', mobileCode)
    },
    updateVerificationTarget ({ commit }, verificationTarget) {
      commit('updateVerificationTarget', verificationTarget)
    },
    updateVerificationSessionId ({ commit }, sessionId) {
      commit('updateVerificationSessionId', sessionId)
    },
    updateVerificationAccountStatus ({ commit }, accountStatus) {
      commit('updateVerificationAccountStatus', accountStatus)
    },
    updateVerificationExpiresTime ({ commit }, expiresTime) {
      commit('updateVerificationExpiresTime', expiresTime)
    },
    updateVerificationCountdown ({ commit }, countdown) {
      commit('updateVerificationCountdown', countdown)
    },
    updateFavoritesMarket ({ commit }, list) {
      commit('updateFavoritesMarket', list)
    },
    updateUserInfo ({ commit }, userInfo) {
      commit('updateUserInfo', userInfo)
    },
    updateLangs ({ commit }, list) {
      commit('updateLangs', list)
    },
    LogOut ({ dispatch }) {
      dispatch('updateToken', '')
      dispatch('updateUserInfo', {})
      dispatch('updateTotalAssets', { amount: 0, available_amount: 0, position_amount: 0 })
    }
  },
  modules: {
  }
})
