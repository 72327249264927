<template>
  <header class="tsr-header" v-if="false">
    <div class="login" @click="onLogin" v-if="showIcon">
      Login
    </div>
    <select id="i18-select" @change="onChg" :value="locale">
      <option value="zh_CN">简体中文</option>
      <option value="zh_HK">繁体中文</option>
      <option value="en_US" checked>English</option>
      <option value="pt_PT" checked>Português</option>
    </select>
  </header>
</template>

<script>
import { languageList } from '@/api/common.js'
import { mapMutations, mapActions, mapGetters } from 'vuex'
export default {
  computed: {
    showIcon () {
      return !this.token
    },
    showDevHead () {
      return process.env.NODE_ENV === 'development'
    },
    ...mapGetters(['locale', 'token'])
  },
  data () {
    return {
      languageListObj: []
    }
  },
  mounted () {
    this.languageListFn()
  },
  methods: {
    languageListFn () {
      languageList().then((res) => {
        if (res) {
          this.updateLangs(res.data)
        }
        this.$toast.clear()
      })
    },
    onChg ({ target }) {
      this.updateLocale(target.value)
      this.$i18n.locale = target.value
    },
    onLogin () {
      this.$router.push('/register/login')
    },
    ...mapMutations([// 采用解构的方式引入
      'updateLocale'
    ]),
    ...mapActions(['updateLangs'])
  }
}
</script>

<style lang="less">
.tsr-header {
  width: 100%;
  height: 64px;
  font-size: 14px;
  background-color: white;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 0 0;
  box-shadow: 0 1px 4px rgba(0,21,41,.08);
  .login {
    margin-right: 10px;
  }
  select {
    margin-right: 10px;
  }
}
</style>
