export default {
  bind: (el, binding) => {
    let debounceTime = binding.value // 防抖时间
    if (!debounceTime) { // 用户若不设置防抖时间，则默认1s
      debounceTime = 1000
    }
    let cbFun
    el.addEventListener('click', event => {
      if (!cbFun) { // 第一次执行
        cbFun = setTimeout(() => {
          cbFun = null
        }, debounceTime)
      } else {
        event && event.stopImmediatePropagation()
      }
    }, true)
  }
}
