module.exports = {
  nav: {
    home: 'Início',
    market: 'Mercados',
    trade: 'Negociar',
    futures: 'Futuros',
    wallets: 'Carteiras',
    login: 'Entrar',
    profile: 'Perfil',
    me: 'Eu',
    unLogin: 'As informações de login expiraram, você precisa fazer login novamente',
    unAuth: 'As permissões expiraram, por favor faça login novamente',
    reLogin: 'por favor faça login novamente',
    error: 'Erro interno do servidor',
    search: 'Pesquisar',
    cancel: 'Cancelar',
    userNoExist: 'usuário não existe',
    prompted: 'Avisado',
    successText: 'Sucesso'
  },
  checkNetwork: {
    tipMessage: 'Tempo de solicitação esgotado, verifique o status da sua rede',
    confirmButtonText: 'OK'
  },
  anynode: 'anynode',
  welcome: 'Bem-vindo ao Anynode Global',
  next: 'Próximo',
  cancel: 'Cancelar',
  confirm: 'Confirmar',
  search: 'Pesquisar',
  region: 'Região',
  select: 'Selecionar',
  or: 'ou',
  refresh: 'Atualizar',
  country: {
    title: 'Selecione o código de área'
  },
  auth: {
    login: 'Entrar',
    OPTlogin: 'Entrar com OPT',
    signup: 'Registrar',
    homtTip: 'Uma mensagem solicita o usuário a completar a operação de login e registro.',
    email: 'Email',
    phone: 'Telefone',
    password: 'Senha',
    enterPhone: 'Número de telefone',
    enterPassword: 'Senha de login',
    forgot: 'Esqueceu a senha?',
    code: 'Código',
    submit: 'Enviar',
    welcomeSignUp: 'Registrar com Anynode',
    welcomeLogIn: 'Bem-vindo de volta!',
    resetPasswordTip: 'Saques e negociações OTC não permitidos por 24 horas após a redefinição da senha.',
    referralCode: 'Código de indicação',
    loginError: 'Conta ou senha incorretas. Você tem 4 tentativas restantes.',
    emialErrorTip: 'Por favor insira um email válido',
    phoneErrorTip: 'Por favor insira um telefone válido',
    OPTLogin: 'Registrar/Entrar',
    passwordLogin: 'Login com senha',
    newPassword: 'Nova senha',
    confirmPassword: 'Confirmar senha',
    confirmPwdErrorTip: 'As senhas digitadas são diferentes',
    newPwdMinLengthTip: 'No mínimo 8 caracteres incluindo',
    newPwdMaxLengthTip: 'No máximo 16 caracteres incluindo',
    newPwdDigitTip: 'Complexidade da senha muito baixa',
    newPwdLetterTip: 'Complexidade da senha muito baixa',
    setPasswordTitle: 'Definir uma senha',
    sendVerificationCode: 'Enviar código de verificação',
    verificationTitle: 'Verificação de segurança',
    verificationLabel: 'Código de verificação do telefone/email',
    recent: 'Recente',
    resetPassWord: 'Redefinir sua senha'
  },
  market: {
    favorites: 'Favoritos',
    all: 'Todos',
    name: 'Nome',
    vol: 'Vol',
    currency: 'Vol',
    trading: 'Par de negociação',
    price: 'Último preço',
    change: 'Variação 24h',
    market: 'Mercado',
    action: 'Ação',
    hot: 'Quente',
    newest: 'Novo',
    topVolume: 'Top Volume',
    topGainers: 'Maiores Ganhadores',
    indexPrice: 'Preço do Índice',
    markPrice: 'Preço de Marcação',
    lastPrice: 'Último preço',
    highPrice: 'Alto',
    lowPrice: 'Baixo',
    currencyPrice: 'Vol',
    today: 'Hoje',
    days7: '7 Dias',
    days30: '30 Dias',
    days90: '90 Dias',
    days180: '180 Dias',
    year1: '1 Ano',
    bid: 'Oferta',
    ask: 'Pergunta',
    perprtual: 'Perpétuo',
    noDataText: 'Sem Dados'
  },
  trade: {
    orderBook: 'Livro de Ordens',
    marketTrades: 'Negociações',
    data: 'Dados de Negociação',
    projectDetails: 'Informações',
    chart: 'Gráfico',
    trades: 'Negociações',
    buy: 'Comprar',
    sell: 'Vender',
    fundingRate: 'Taxa de Financiamento',
    countdown: 'Contagem Regressiva',
    fundingText: 'A taxa de pagamento trocada entre as posições longas e curtas para o próximo pagamento. Se a taxa de pagamento for positiva, os longos pagam os curtos. Se negativo, os curtos pagam os longos.',
    indexPrice: 'Preço do Índice',
    fairPrice: 'Preço Justo',
    turnover: 'Faturamento',
    volume: 'Volume',
    low: 'Baixo',
    high: 'Alto',
    price: 'Preço',
    more: 'Mais',
    depth: 'Profundidade',
    line: 'Linha',
    m1: '1m',
    m3: '3m',
    m5: '5m',
    m30: '30m',
    m15: '15m',
    h1: '1h',
    h2: '2h',
    h4: '4h',
    h6: '6h',
    h8: '8h',
    h12: '12h',
    d1: '1d',
    d3: '3d',
    w1: '1s',
    M1: '1M',
    M: 'M',
    time: 'Tempo',
    quantity: 'Quantidade',
    favourite: 'adicionado aos favoritos',
    unFavourite: 'Removido dos favoritos'
  },
  wallets: {
    amount: 'Quantidade',
    overview: 'Visão geral',
    buy: 'Comprar',
    deposit: 'Depositar',
    spot: 'Spot',
    futures: 'Futuros',
    funding: 'Financiamento',
    totalValue: 'Valor total',
    withdraw: 'Retirar',
    totalBalance: 'Saldo total',
    position: 'Posições',
    totalAssets: 'Ativos totais',
    transactionHistory: 'Histórico de transações',
    sell: 'Vender',
    cross: 'Cross',
    isolated: 'Isolado',
    pnl: 'PNL',
    roi: 'ROI',
    roe: 'ROE',
    size: 'Tamanho',
    margin: 'Margem',
    marketClose: 'Fechamento do mercado',
    marginLevel: 'Nível de margem',
    emptyPrice: 'Preço vazio',
    marketPrice: 'Preço de mercado',
    liqPrice: 'Preço de liquidação',
    walletBalance: 'Saldo da carteira',
    unrealizedPNL: 'PNL não realizado',
    marginBalance: 'Saldo da margem',
    availableForWithdrawal: 'Disponível para retirada'
  },
  history: {
    amount: 'Quantidade',
    fee: 'Taxa',
    time: 'Horário',
    received: 'Recebido',
    withdraw: 'Retirar',
    futuresHistory: 'Histórico de Futuros',
    positionHistorry: 'Histórico de Posições',
    ordersHistory: 'Histórico de Ordens',
    orderDetail: 'Detalhes da Ordem',
    closeLong: 'Fechar Longo',
    closeShort: 'Fechar Curto',
    openShort: 'Abrir Curto',
    openLong: 'Abrir Longo',
    filled: 'Preenchido',
    perpetual: 'Perpétuo',
    reduceOnly: 'Somente reduzir',
    price: 'Preço',
    symbol: 'Símbolo',
    orderNo: 'Número da Ordem',
    type: 'Tipo',
    filledAmount: 'Preenchido/Quantidade',
    AvgPrice: 'Preço Médio',
    realizedPNL: 'PNL Realizado',
    timeCreated: 'Horário de Criação',
    timeUpdated: 'Horário de Atualização',
    tradeDetails: 'Detalhes da Negociação',
    date: 'Data',
    quantity: 'Quantidade',
    role: 'Função'
  },
  coinList: {
    crypto: 'Crypto',
    trending: 'Tendências',
    coinList: 'Lista de Moedas',
    noFound: 'Nenhum registro encontrado',
    searchHistory: 'Histórico de Pesquisa',
    placeholder: 'Pesquisar Moeda',
    cash: 'Dinheiro'
  },
  deposit: {
    deposit: 'Depositar',
    depositTip1: 'Enviar apenas',
    depositTip11: 'para este endereço de depósito.',
    depositTip2: 'Este endereço não suporta depósito de tokens não fungíveis, por favor vá para a página de NFT para depositar NFT.',
    chooseNetwork: 'Escolha a rede para obter o endereço de depósito',
    address: 'Endereço',
    selectedWallet: 'Carteira Selecionada',
    spotWallet: 'Carteira Spot',
    minimum: 'Depósito mínimo',
    expectedArrival: 'Chegada esperada',
    expectedUnlock: 'Desbloqueio esperado',
    networkConfirmations: 'confirmações de rede',
    contractInformation: 'Informações do contrato',
    saveAsImage: 'Salvar como Imagem',
    shareAddress: 'Compartilhar Endereço'
  },
  withdraw: {
    withdraw: 'Retirar',
    send: 'Enviar',
    sendTip1: 'Enviar',
    sendTip2: 'para o endereço de criptomoeda',
    address: 'Endereço',
    addressIptTip: 'Pressione e segure para colar',
    amount: 'Quantidade',
    amountIptTip: 'Mínimo 0',
    methodTitle1: 'Enviar via Rede de Criptomoedas',
    methodTip1: 'Enviar para um endereço de criptomoeda conhecido via rede de criptomoedas.',
    methodTitle2: 'Enviar via Email/Telefone/ID',
    methodTip2: 'Enviar para uso xxxx. Fácil, instantâneo, sem taxas',
    token: 'Token',
    balance: 'Saldo',
    fee: 'Taxa',
    available: 'Disponível',
    withdrawalLimit: 'Limite de retirada de 24h',
    wraningTip: 'Não retire diretamente para uma captação de recursos ou ICO. Não creditaremos sua conta com tokens dessa venda.',
    submit: 'Enviar',
    invoice: 'Fatura',
    invoiceDialogContent: 'Por favor, insira o endereço ou a fatura. Retiradas via rede BTC Lightning exigem uma fatura gerada pela plataforma de depósito.',
    iKonw: 'Eu sei',
    selectAddress: 'Selecionar Endereço',
    insufficientBalance: 'Saldo Insuficiente'
  },
  chooseNetwork: {
    network: 'Rede',
    chooseNetwork: 'Escolha a Rede',
    slelectNetwork: '',
    slelectNetworkTip: 'Por favor, escolha a rede primeiro',
    tip: 'Por favor, observe que apenas as redes suportadas na plataforma Binance são exibidas. Se você depositar através de outra rede, seus ativos podem ser perdidos.'
  },
  pullRefresh: {
    pullingText: ' ',
    loosingText: ' ',
    loadingText: 'Carregando',
    successText: ' ',
    finishedText: 'Fim'
  },
  features: {
    allHolder: 'Cross',
    halfHolder: 'Isolado',
    open: 'Abrir',
    close: 'Fechar',
    single: 'S',
    union: 'M',
    surplus: 'TP',
    loss: 'SL',
    maxOpen: 'Max Aberta',
    cost: 'Custo',
    openLong: 'Abrir Longo',
    openShort: 'Abrir Curto',
    advanced: 'Avançado',
    buy: 'Comprar',
    sell: 'Vender',
    amount: 'Quantidade',
    limit: 'Limite',
    avbl: 'Disp',
    perpetual: 'Perpétuo',
    takeProfit: 'Take Profit',
    stopLoss: 'Stop Loss',
    min: 'Min',
    slippage: 'Tolerância de Deslizamento',
    adjustLever: 'Ajustar Alavancagem',
    'usd@mfeatures': 'USD@-M Futuros',
    insuffcientMargin: 'Margem Insuficiente',
    emptyNumber: 'indique por favor um número válido',
    purchaseError: 'Quantidade de compra anormal',
    closeText: 'A quantidade de encerramento não pode ser superior à quantidade da exploração',
    orderMin: 'Ordem nocional não pode ser inferior a 5.1 USDT, Isso não se aplica a reduzir apenas ordens.',
    stepNotice: 'Sua carteira e ativos atuais serão removidos permanentemente deste aplicativo. Essa ação não pode ser desfeita. Sua carteira e ativos atuais serão removidos permanentemente deste aplicativo. Essa ação não pode ser desfeita.'
  },
  profile: {
    language: 'Idioma',
    help: 'Ajuda',
    helpCenter: 'Central de Ajuda',
    customerService: 'Atendimento ao Cliente',
    security: 'Segurança',
    aboutBewincoin: 'Sobre a Bewincoin',
    logOut: 'Sair',
    logOutText: 'Você tem certeza que deseja sair?',
    copy: 'Copiado',
    phone: 'Número de Telefone',
    email: 'Email',
    loginPwd: 'Senha de Login',
    withdrawPwd: 'Senha de Retirada',
    faceID: 'Face ID',
    patternPwd: 'Senha de Padrão',
    inUse: 'Em uso',
    changeNow: 'Mudar Agora',
    nickApproveText: 'Levará alguns minutos para revisarmos e aprovarmos um novo nome global ou apelido.',
    nickChangeText: 'Seu apelido é visível para todos os usuários. Você pode alterá-lo 3 vezes antes do final deste ano. A cota é redefinida anualmente.',
    servicesAgreement: 'Contrato de Serviços',
    checkUpdate: 'Verificar atualizações',
    clearCache: 'Limpar cache',
    nav: {
      profile: 'Perfil',
      picture: 'Foto de Perfil',
      nickname: 'Apelido',
      language: 'Idioma',
      about: 'Sobre a Bewincoin',
      information: 'Informações'
    },
    verification: 'Verificação',
    add: 'Adicionar',
    code: 'código',
    emailCodeTip: 'Digite o código de verificação de 6 dígitos enviado para ',
    phoneCodeTip: 'Digite o código de verificação de 6 dígitos enviado para ',
    emailCodeLabel: 'Código de verificação por email',
    phoneCodeLabel: 'Código de verificação por telefone',
    phoneNoneTip: 'A verificação do número de telefone adiciona uma camada adicional de segurança às suas retiradas e conta.',
    emailNoneTip: 'A verificação por email adiciona uma camada adicional de segurança às suas retiradas e conta.',
    added: 'Adicionado',
    chooseVerification: 'Escolha a Verificação',
    change: 'Alterar',
    disabled: 'Desativado',
    linked: 'Vinculado',
    enter: 'Digite',
    verificationCode: 'Código de Verificação',
    getCode: 'Obter Código'
  }
}
