// 添加请求拦截器
import axios from 'axios'
import { getToken, removeToken } from '@/utils/token.js'
import { Toast } from 'vant'
import Vue from 'vue'
import i18n from '@/i18n'

Vue.use(Toast)
const ExpiredTime = 60
axios.defaults.baseURL = `${process.env.VUE_APP_BASE_API}`
const http = axios.create({
  timeout: ExpiredTime * 10000,
  withCredentials: true
  // baseURL: `${process.env.VUE_APP_BASE_API}`
})
let loadToast = null
// 请求拦截
http.interceptors.request.use(
  function (config) {
    config.headers = {
      'Content-Type': 'application/json',
      Lang: i18n.locale
    }
    if (getToken()) {
      config.headers.Authorization = getToken()
    }
    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 响应拦截器
http.interceptors.response.use(
  function (response) {
    return errorHandler(response)
  },
  function (error) {
    console.error('reject', error)
    errorHandler(error.response || error)
  }
)
// 错误处理
function errorHandler (errorResponse) {
  // 返回结果不规范，暂时这样判断
  const resStatus = errorResponse.data
    ? +errorResponse.data.code || errorResponse.status
    : errorResponse.status || errorResponse

  if (resStatus === 200 || resStatus === 0) {
    return errorResponse.data
  }
  let msg = handleResStatus(resStatus)
  if (errorResponse.data) {
    msg = errorResponse.data.message || errorResponse.data.error
  }
  if (loadToast) {
    Toast.fail(msg)
  }
  console.error(msg, '错误信息')
  return Promise.reject(errorResponse)
}

// 根据 HTTP 码 返回错误提示
function handleResStatus (resStatus) {
  let msg = ''
  const $t = i18n.messages[i18n.locale]
  switch (resStatus) {
    case 301: // 301: 登录信息已过期，跳转到登录页面重新获取token
      msg = $t.nav.unLogin
      removeToken()
      break
    case 302: // 302: 未登录,登录信息已过期，跳转到登录页面重新获取token
      msg = $t.nav.unLogin
      removeToken()
      break
    case 401:
      msg = $t.nav.unAuth
      removeToken()
      break
    case 6000000:
      msg = $t.nav.unAuth
      removeToken()
      break
    case 403: // 403 token过期
      msg = $t.nav.reLogin
      break
    default:
      msg = $t.nav.error
  }
  return msg
}

export function requestGet (url, params = {}, config = {}) {
  return http.get(url, { params: params, ...config })
}

export function requestPost (url, data = {}, config = { loading: true }) {
  if (config.loading) {
    loadToast = {}
    Toast.loading({
      mask: true,
      duration: 0 // 必须手动关闭
    })
  } else {
    // if (loadToast) {
    //   Toast.clear()
    // }
    // loadToast = null
  }
  let formData = data
  if (config.formData) {
    formData = new FormData()
    Object.keys(data).forEach((item) => {
      formData.append(item, data[item])
    })
  }
  return http.post(url, formData, config)
}
