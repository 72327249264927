import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from './store/index'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: sessionStorage.getItem('locale') || store.state.locale,
  messages: {
    zh_CN: require('./common/language/zh.js'),
    en_US: require('./common/language/en.js'),
    pt_PT: require('./common/language/pt.js')
  }
})

export default i18n
