import { Dialog } from 'vant'
import Vue from 'vue'
Vue.use(Dialog)
const tipMessage = {
  zh_CN: '请求超时，请检查你的网络状态',
  en_US: 'Request timed out, please check your network status'
}
const confirmButtonText = {
  zh_CN: '确定',
  en_US: 'OK'
}
var online = navigator.onLine
function updateOnlineStatus () {
  // console.log(window.$_Vue.$t, 'window.$_Vue.$t')
  const lang = window.$_Vue.$store.state.locale
  online = navigator.onLine
  if (!online) {
    Dialog.confirm({
      title: '',
      message: tipMessage[lang],
      showCancelButton: false,
      confirmButtonText: confirmButtonText[lang]
    }).then(() => {
      // on confirm
    })
  }
}
// navigator.connection 原本打算使用这个方法 但是兼容性不好 放弃
window.addEventListener('online', updateOnlineStatus)
window.addEventListener('offline', updateOnlineStatus)
// online 1. true 联网 2. false 断网
