import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
// import Vant from 'vant'
import 'vant/lib/index.css'
import 'lib-flexible/flexible'
import debounce from '@/plugins/debounce'
import './utils/checkNetwork'

import { Tabbar, TabbarItem, Tabs, Tab, NavBar, Icon, Popover, Button, Field, NumberKeyboard, ActionSheet, Search, Popup, Form, Dialog, Radio, RadioGroup } from 'vant'

Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Tabs)
Vue.use(Tab)
Vue.use(NavBar)
Vue.use(Icon)
Vue.use(Popover)
Vue.use(Button)
Vue.use(Field)
Vue.use(NumberKeyboard)
Vue.use(ActionSheet)
Vue.use(Popup)
Vue.use(Form)
// 函数防抖
Vue.directive('debounce', debounce)
Vue.use(Search)
Vue.use(Dialog)
Vue.use(Radio)
Vue.use(RadioGroup)

Vue.use(VueClipboard)

Vue.config.productionTip = false
Vue.prototype.$EventBus = new Vue()

window.$_Vue = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
